<template>
  <div :style="getElementStyle" v-loading="loading">
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        :style="computedStyles"
      >
        <label for="text" style="margin-right: 5px">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <div v-if="!isView">
          <el-select
            v-model="form[data.key]"
            :placeholder="data.placeholder"
            :suffix-icon="data.properties.el_icon"
            :style="getStyle"
            @change="handleSelectedValue"
          >
            <el-option
              v-for="(option, index) in options"
              :label="option[selectedColumnLabel]"
              :value="option[selectedColumnKey]"
              :key="index"
            >
            </el-option>
          </el-select>
          <span
            v-if="isActive || isDataTableField"
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" />
          </span>
        </div>
        <div v-else>
          {{ form[selectedValue + "/name"] }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  components: {},
  props: [
    "data",
    "value",
    "isActive",
    "isDataTableField",
    "isInDataTable",
    "form",
    "fromRepeatable",
    "fieldsData",
    "entityDataId",
    "entityId",
    "isHideField",
    "highlight",
    "fromEntityViews",
    "isView",
    "hasLabel",
  ],
  computed: {
    selectedColumnLabel() {
      return this.data.integration_settings.selectedColumnLabel;
    },
    selectedColumnKey() {
      return this.data.integration_settings.selectedColumnKey;
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      return `height:${this.data.height - 30}px;`;
      // width:${this.data.width}px;overflow:auto;`
      // Tharuni Start
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },

    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      integrations: [],
      selectedValue: this.data.key,
      loading: false,
    };
  },
  watch: {
    form: {
      handler(newForm, oldForm) {
        console.log("Form changed", newForm, oldForm);
        this.handleFormChange();
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    if (!this.isView) {
      await this.fetchIntegrations();
      await this.fetchAllPredefinedIntegrations();
      await this.getDetails(this.data);
    }
    this.loading = false;
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
    async fetchIntegrations() {
      let params = {
        limit: 10,
        page: 1,
      };
      let integrationData = await postAPICall("GET", `integrations`, params);
      this.integrations = integrationData.data;
      return this.integrations;
    },
    async getDetails() {
      const connectionId = this.data.integration_settings.selectedIntegration;
      if (
        !this.data.integration_settings.templateDependencies ||
        (this.data.integration_settings.templateDependencies &&
          this.data.integration_settings.templateDependencies.length === 0)
      ) {
        const selectedEventKey = this.data.integration_settings.selectedEvent;
        const integrationDetail = this.integrations.find(
          (integration) => integration.connection_id === connectionId
        );
        const predefinedIntegration = this.predefinedIntegrations.find(
          (integration) => integration.key === integrationDetail.application
        );
        const Event = predefinedIntegration.events.find(
          (event) => String(event._id) === selectedEventKey
        );
        const asset = Event.asset;
        const requestBody = {
          selectedApplication: integrationDetail.application,
          selectedEvent: asset,
          selectedIntegration:
            this.data.integration_settings.selectedIntegration,
          requiredFields: this.data.integration_settings.requiredFields
            ? this.data.integration_settings.requiredFields
            : {},
        };
        const apiResponse = await this.fetchEventData(requestBody);
        if (apiResponse.status === true) {
          this.options = apiResponse?.data || [];
        } else {
          this.options = [];
        }
      } else {
        let requiredFields = {};
        let macthedField;
        let templateDependencies =
          this.data.integration_settings.templateDependencies;
        let filteredValues = templateDependencies.filter(
          (key) => this.form[key]
        );
        if (templateDependencies.length === filteredValues.length) {
          await templateDependencies.forEach((dependencyKey) => {
            macthedField = this.fieldsData.find(
              (field) => field.key == dependencyKey
            );
            if (macthedField) {
              let key = macthedField.integration_settings.selectedEvent;
              const predefinedIntegration = this.predefinedIntegrations.find(
                (integration) =>
                  integration._id ===
                  this.data.integration_settings.selectedApplication
              );
              const Event = predefinedIntegration.events.find(
                (event) => String(event._id) === key
              );
              const asset = Event.asset;
              this.$set(requiredFields, asset, this.form[dependencyKey]);
            }
          });

          const predefinedIntegration = this.predefinedIntegrations.find(
            (integration) =>
              integration._id ===
              this.data.integration_settings.selectedApplication
          );
          const event = predefinedIntegration.events.find(
            (event) =>
              String(event._id) === this.data.integration_settings.selectedEvent
          );
          const connectionId =
            macthedField.integration_settings.selectedIntegration;
          const integrationDetail = this.integrations.find(
            (integration) => integration.connection_id === connectionId
          );
          const requestBody = {
            selectedApplication: integrationDetail.application,
            selectedEvent: event.asset,
            selectedIntegration: connectionId,
            requiredFields: requiredFields,
          };
          const apiResponse = await this.fetchEventData(requestBody);

          if (apiResponse.status === true) {
            this.options = apiResponse?.data || [];
          } else {
            this.options = [];
          }
        }
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async handleFormChange() {
      const templateDependencies = this.data.integration_settings
        .templateDependencies
        ? this.data.integration_settings.templateDependencies
        : [];
      if (templateDependencies && templateDependencies.length > 0) {
        let dependencyValues = {};
        let allDependenciesExist = true;
        templateDependencies.forEach((dependencyKey) => {
          if (this.form[dependencyKey]) {
            dependencyValues[dependencyKey] = this.form[dependencyKey];
          } else {
            allDependenciesExist = false;
          }
        });
        if (allDependenciesExist) {
          let requiredFields = {};
          let macthedField;
          templateDependencies.forEach((dependencyKey) => {
            macthedField = this.fieldsData.find(
              (field) => field.key == dependencyKey
            );
            if (macthedField) {
              let key = macthedField.integration_settings.selectedEvent;
              const predefinedIntegration = this.predefinedIntegrations.find(
                (integration) =>
                  integration._id ===
                  this.data.integration_settings.selectedApplication
              );
              const Event = predefinedIntegration.events.find(
                (event) => String(event._id) === key
              );
              const asset = Event.asset;
              this.$set(requiredFields, asset, this.form[dependencyKey]);
            }
          });
          const connectionId =
            macthedField.integration_settings.selectedIntegration;
          const selectedEventKey = this.data.integration_settings.selectedEvent;
          const integrationDetail = this.integrations.find(
            (integration) => integration.connection_id === connectionId
          );
          const predefinedIntegration = this.predefinedIntegrations.find(
            (integration) => integration.key === integrationDetail.application
          );
          const Event = predefinedIntegration.events.find(
            (event) => String(event._id) === selectedEventKey
          );
          const asset = Event.asset;
          const requestBody = {
            selectedApplication: integrationDetail.application,
            selectedEvent: asset,
            selectedIntegration:
              macthedField.integration_settings.selectedIntegration,
            requiredFields: requiredFields,
          };
          let apiResponse = await this.fetchEventData(requestBody);
          if (apiResponse.status === true) {
            this.options = apiResponse?.data || [];
          } else {
            this.options = [];
          }
        } else {
          console.log("Not all dependencies are available in the form.");
        }
      }
    },
    handleSelectedValue() {
      let value = this.options.find(
        (option) => option[this.selectedColumnKey] == this.form[this.data.key]
      );
      this.$set(
        this.form,
        this.data.key + "/name",
        value[this.selectedColumnLabel]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
